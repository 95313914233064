<template>
  <div>
    <b-container fluid="xxl" class="mt-2">
      <b-row class="mt-4">
        <b-col cols="4" class="p-0">
          <PageTitle v-bind:title="'Manuscripts'" />
        </b-col>
        <b-col cols="8" class="text-right" v-if="!config.editable">
          <BibLabelSelectPI class="mb-3" />
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <div v-if="$store.state.user.settings.isBiblabelFiltered && config.editable" class="mb-3 text-orange">
            <b-icon icon="exclamation-circle-fill" aria-hidden="true"></b-icon>
            Entries in this table are filtered by personal preference for bibliography set in user settings
            ({{ $store.getters.getBibLabelsByIds($store.state.user.settings.biblabels).map(biblabel => biblabel.label).join(', ') }})
          </div>
          <b-button v-if="config.editable" variant="primary" size="sm" :disabled="!$store.getters.checkRole('legam.bib.manuscript.add')" class="float-right mb-4" v-b-modal.modal-new-manuscript>
            <b-icon icon="plus-circle-fill" aria-hidden="true"></b-icon>
            Add new manuscript
          </b-button>
          <b-modal v-if="config.editable" id="modal-new-manuscript" title="New manuscript" ok-title="Save" @ok="saveManuscript" size="lg">
            <ManuscriptNewEdit />
          </b-modal>
          <LegamDataTables v-if="config.editable" v-bind="$data" :additionalData="tableAdditionalData" />
          <LegamDataTablesClientSide
            v-else
            v-bind="$data"
            @click="openDetails"
            :additionalData="tableAdditionalData"
            :additionalDataFilter="tableAdditionalDataFilter"
            ref="PITable"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import router from '@/router'
  import PageTitle from '@/components/PageTitle.vue'
  import LegamDataTables from '@/components/LegamDataTables.vue'
  import LegamDataTablesClientSide from '@/components/LegamDataTablesClientSide.vue'
  import BibLabelSelectPI from '@/components/Bibliography/BibLabelSelectPI.vue'
  import tdColAction from "@/components/tdColAction";
  import FilterScriptaLanguageComponentDatatables from "@/components/FilterScriptaLanguageComponentDatatables"
  import ManuscriptNewEdit from "@/components/Bibliography/ManuscriptNewEdit"
  import tdColTooltip from "@/components/tdColTooltip"
  import HelpStore from '@/store/helpstore.js'
  import config from '@/config.js'

  export default {
    name: 'ManuscriptsList',
    data(){
      return {
        columns: [
          { title: this.$t('CodeRepcrit'), field: 'codeRepcrit', sortable: true, searchable: true },
          { title: this.$t('FullCode'), field: 'codeFull', sortable: true, searchable: true },
          { title: this.$t('OldCode'), field: 'codeOld', sortable: true, searchable: true },
          { title: 'Date', field: 'dateSpecPhil', sortable: true, searchable: true },
          { title: this.$t('Reference'), field: 'reference', sortable: true, searchable: true },
          { title: 'Scriptae', field: 'localization.scripta.code', sortable: true, searchable: true, tdComp: tdColTooltip,
            transformData: function(row){
              return {
                shortText: (row.localization && row.localization.scriptas) ? row.localization.scriptas.map(scripta => scripta.code).join(', ') : '',
                longText: (row.localization && row.localization.scriptas) ? row.localization.scriptas.map(scripta => `${scripta.code}${scripta.language ? ' (' + scripta.language.name + ')' : ''}`).join(', ') : '',
                id: row.codeRepcrit.replace(/ /gi, '')
              }
            },
            sortComp: FilterScriptaLanguageComponentDatatables,
          },
          { title: 'Label', field: 'biblabels', sortable: true, searchable: true },
          { title: 'Action', field: 'id', tdComp: tdColAction, thClass: 'tdAction',
            transformData: function(row){
              return [
                {
                  text: null,
                  icon: 'list-ul',
                  id: row.id,
                  to: {name: 'ManuscriptDetails', params: {
                    id: row.id,
                    slug: row.codeRepcritNormalized || "-"
                  }}
                }
              ]
            }
          }
        ],
        columnsPI: [
          { title: 'Code Repcrit', field: 'codeRepcrit', sortable: true, searchable: true },
          { title: 'Full Code', field: 'codeFull', sortable: true, searchable: true },
          { title: 'Old Code', field: 'codeOld', sortable: true, searchable: true },
          { title: 'Date', field: 'dateSpecPhil', sortable: true, searchable: true },
          { title: 'Reference', field: 'reference', sortable: true, searchable: true },
          { title: 'Scriptae', field: 'localization', sortable: true, searchable: true,
            // transformData: function(row){
            //   return {
            //     shortText: (row.localization && row.localization.scriptas) ? row.localization.scriptas.map(scripta => scripta.code).join(', ') : '',
            //     longText: (row.localization && row.localization.scriptas) ? row.localization.scriptas.map(scripta => `${scripta.code}${scripta.language ? ' (' + scripta.language.name + ')' : ''}`).join(', ') : '',
            //     id: row.codeRepcrit.replace(/ /gi, '')
            //   }
            // },
            sortComp: FilterScriptaLanguageComponentDatatables,
          },
          { title: 'Label', field: 'biblabels', sortable: true, searchable: false }
        ],
        url: '/manuscripts/datatables',
        key: 1,
        searchableByColumn: true,
        config: config,
      }
    },
    computed: {
      ...mapState({
        userSettings: state => state.user.settings,
        userSettingsPI: state => state.user.settingsPI
      }),
      tableAdditionalData(){
        return {
          'biblabel': this.userSettings.biblabels,
          'biblabelSuppressed': this.userSettings.biblabelsSuppressed,
        }
      }
    },
    components: {
      PageTitle,
      LegamDataTables,
      LegamDataTablesClientSide,
      ManuscriptNewEdit,
      BibLabelSelectPI,
    },
    methods: {
      tableAdditionalDataFilter(data) {
        if (this.userSettingsPI.biblabels.length) {
          data = data.filter(row => this.userSettingsPI.biblabels.includes(row.biblabels))
        }
        return data
      },
      saveManuscript(){
        this.$store.dispatch('createManuscript', HelpStore.item).then((response) => {
          router.push(`/manuscript/${response.data.codeRepcritNormalized}/${response.data.id}`)
        })
      },
      openDetails(data) {
        router.push(`/manuscript/-/${data.id}`)
      },
    },
    watch: {
      "userSettings"(){
        this.key++
      },
      userSettingsPI: {
        deep: true,
        handler() {
          if (this.$refs.PITable.table) {
            this.$refs.PITable.sortPageFilter()
          }
        }
      },
    }
  }
</script>
