<template>
  <div>
    <b-form-group
      id="code-repcrit-group"
      label="Code Repcrit:"
      label-for="code-repcrit"
    >
      <b-form-input
        id="code-repcrit"
        v-model="model.codeRepcrit"
        placeholder="Code Repcrit"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      id="code-full-group"
      label="Code Full:"
      label-for="code-full"
    >
      <b-form-input
        id="code-full"
        v-model="model.codeFull"
        placeholder="Code Full"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      id="code-old-group"
      label="Code Old:"
      label-for="code-old"
    >
      <b-form-input
        id="code-old"
        v-model="model.codeOld"
        placeholder="Code Old"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      id="comment-status-group"
      label="Comment status:"
      label-for="comment-status"
    >
      <b-form-textarea
        id="comment-status"
        placeholder="Comment Status"
        v-model="model.commentStatus"
        rows="3"
        max-rows="6"
      ></b-form-textarea>
    </b-form-group>
  </div>
</template>

<style scoped>
</style>

<script>
  import HelpStore from '@/store/helpstore.js'

  export default {
    name: 'ManuscriptNewEdit',
    props: ['manuscript'],
    data(){
      return {
        // TODO: fetch from API
        model: (this.manuscript ? this.manuscript : {
          codeRepcrit: null,
          codeFull: null,
          codeOld: null,
          commentStatus: null,
          dates: null,
          datesNum: null,
          commentGeneral: null,
          partOfManuscript: null
        })
      }
    },
    updated(){
      HelpStore.item = this.model
    },
    methods: {
      // selectCategory(category){
      //   this.model.categories.push(category)
      // }
    }
  }
</script>
